import React, {useEffect, useState, useReducer} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {bottomBarState, loaderState, userState} from "../states/common";
import {useNavigate, useParams} from "react-router-dom";
import * as _ from 'lodash'
import LeagueInterface from "../interfaces/league";
import moment, {ISO_8601} from "moment";
import * as SBService from "../helpers/sportbookService";
import toast from "react-hot-toast";
import PlayerCheckinCard from "../components/league/PlayerCheckinCard";
import {getAssetFullUrl} from "../helpers/utils";
import {getRefereeLeagues} from "../helpers/sportbookService";

const LeagueStandingPage = () => {
    const [user, setUser] = useRecoilState(userState);
    const [active, setActive] = useRecoilState(bottomBarState)
    let [playerCheckin, setPlayerCheckin] = useState<any>({})
    const [, setLoader] = useRecoilState(loaderState)
    const {id} = useParams()
    const navigate = useNavigate()

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [leagues, setLeagues] = useState<Array<LeagueInterface>>([{
        id: '',
        name: '',
        startDate: '',
        startDateObj: moment(),
        endDate: '',
        endDateObj: moment(),
        rule: '',
        prize: '',
        banner: '',
        createdAt: "",
        createdBy: "",
        endRegistrationDate: "",
        endRegistrationDateObj: undefined,
        fee: 0,
        formId: null,
        isVerified: false,
        lastModifiedAt: "",
        lastModifiedBy: "",
        logo: "",
        numberParticipants: 0,
        startRegistrationDate: "",
        startRegistrationDateObj: null,
        minMatchesPerWeek: 0,
        minWeekReachMinMatchesRequired: 0,
        venues: []
    }])

    const [league, setLeague] = useState<LeagueInterface>({
        id: '',
        name: '',
        startDate: '',
        startDateObj: moment(),
        endDate: '',
        endDateObj: moment(),
        rule: '',
        prize: '',
        banner: '',
        createdAt: "",
        createdBy: "",
        endRegistrationDate: "",
        endRegistrationDateObj: undefined,
        fee: 0,
        formId: null,
        isVerified: false,
        lastModifiedAt: "",
        lastModifiedBy: "",
        logo: "",
        numberParticipants: 0,
        startRegistrationDate: "",
        startRegistrationDateObj: null,
        minMatchesPerWeek: 0,
        minWeekReachMinMatchesRequired: 0,
        venues: []
    })


    useEffect(() => {
        setActive('league-players')
        const loadData = async function () {
            setLoader(true)
            const listLeague = await SBService.getRefereeLeagues({});
            setLeagues(_.filter(listLeague.data, item => item != null))
            if (listLeague.data[0]) {
                setLeague(listLeague.data[0])
            }
            setLoader(false)
        }
        loadData()
    }, [])


    let [, setCurrentPage] = useState(1)
    let [, setIsPlayerNextPage] = useState(true)

    const updateCheckinPlayer = (playerId: string, isCheckin: boolean) => {
        const indexOfPlayer = playerCheckin.findIndex((item:any) => item.id === playerId)
        if (indexOfPlayer >= 0) {
            playerCheckin[indexOfPlayer].isCheckin = isCheckin;
            setPlayerCheckin(playerCheckin)
        }

        setLoader(false)

    }


    const loadPlayerByLeagueId = async (leagueId: string, page = 1) => {
        const leaguePlayers = await SBService.getPlayersInLeague(leagueId, 50, page)
        if (leaguePlayers.data) {
            if (page === 1) {
                playerCheckin = leaguePlayers.data
            } else {
                playerCheckin = playerCheckin.concat(playerCheckin.data)
            }
            playerCheckin = _.orderBy(playerCheckin, ['isCheckin'], ['desc'])
            setPlayerCheckin(playerCheckin)
        }
    }

    useEffect(() => {
        if (league && league.id) {
            loadPlayerByLeagueId(league.id, 1)
        }
    }, [league]);

    const updateChangeLeague = (event: any) => {
        const indexOfLeague = leagues.findIndex((item:any) => item.id === event.target.value)
        if (indexOfLeague > 0) {
            setLeague(leagues[indexOfLeague])
        }
    }


    return <div className="py-6">
        <div className="flex items-center">
            <img className="w-[40px] rounded-full" src={getAssetFullUrl(user.profilePicture)}/> <span
            className="ml-3 text-sm font-exo text-gray-11">{user.name}</span>
        </div>
        <h3 className="text-[--dark-neutral-gray-12] text-xl mt-2">DANH SÁCH VẬN ĐỘNG VIÊN</h3>
        {leagues.length > 0 && leagues[0].id ?
            <div className="w-full mt-2 bg-[#2D2D46] pr-2 rounded py-2">
                <select className="w-full p-2 rounded font-exo text-sm bg-[#2D2D46] text-gray-11" onChange={(event) => updateChangeLeague(event)}>
                    {!_.isEmpty(leagues) ? leagues.map((r: any) => {
                        return <option key={r.id} value={r.id}>{r.name}</option>
                    }) : ''}
                </select>
            </div>
            : <></>}
        <div className="league-content-tab rounded bg-[#1E1E32] w-full p-2 mt-2">
            <div className='mt-4 text-sm'>
                <div className="flex font-bold">
                    <span className="uppercase text-sm opacity-70 w-[10%] pl-3">#</span>
                    <span className="uppercase text-sm opacity-70 w-[10%] pl-1">điểm</span>
                    <span className="uppercase text-sm opacity-70 w-[40%]">Cơ thủ</span>
                    <span className="uppercase text-sm opacity-70 w-[20%]">Hạng</span>
                    <span className="uppercase text-sm opacity-70 w-[20%]">Check-in</span>
                </div>
                {league && league.id ? <div className="league-player h-[calc(100vh/2)] overflow-auto">
                    {!_.isEmpty(playerCheckin) ? playerCheckin.map((player: object, index: number) => {
                        if (!_.isEmpty(player)) return <PlayerCheckinCard key={`player-checkin-leagues-${index}`}
                                                                          player={player} onUpdateCheckin={(playerId, isCheckin: boolean) => updateCheckinPlayer(playerId, isCheckin)}
                                                                          index={index} leagueId={league.id}/>
                    }) : ''}
                </div> : <></>}

            </div>
        </div>

        <div>
            <p className="uppercase mb-2 mt-6 font-medium"></p>
        </div>


    </div>
}

export default LeagueStandingPage