import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import UserCard from "../../components/userCard";
import * as _ from 'lodash'
import * as SBService from "../../helpers/sportbookService";
import {useRecoilState} from "recoil";
import {bottomBarState, loaderState, showBottomBarState, userState} from "../../states/common";
import toast from "react-hot-toast";
import moment from "moment";
import 'moment/locale/vi'
import {TETabs, TETabsContent, TETabsItem, TETabsPane} from "tw-elements-react";
import HistoryItem from "../../components/history-item";
import {getAssetFullUrl} from "../../helpers/utils";

const ProfilePage = () => {
    const [active, setActive] = useRecoilState(bottomBarState)
    const [user, setUser] = useRecoilState(userState)
    const [, setLoader] = useRecoilState(loaderState)
    const navigate = useNavigate();
    const [waitingConfirmMatches, setWaitingConfirmMatches] = useState<any>([])
    const [confirmedMatches, setConfirmedMatches] = useState<any>([])
    const [matches, setMatches] = useState<any>([])

    useEffect(() => {
        document.title = "SB Referee - Profile"
        setLoader(true)
        setActive('confirm-match')

        setWaitingConfirmMatches([
        ])

        setConfirmedMatches([
        ])

        const getAllMatches = async () => {
            const rspConfirmed = await SBService.getAllMatches({status: 'SUBMITTED'})
            setWaitingConfirmMatches(rspConfirmed.data)
            const rspEnded = await SBService.getAllMatches({status: 'ENDED'})
            setConfirmedMatches(rspEnded.data)
        }

        getAllMatches()

    }, [])



    const [basicActive, setBasicActive] = useState("tab1");
    const handleBasicClick = (value: string) => {
        if (value === basicActive) {
            return;
        }
        setBasicActive(value);
    };

    useEffect(() => {
        setLoader(false)
        if (!localStorage.getItem('token')) {
            navigate('/auth/login')
        }

        const loadUser = async () => {
            try {
                const { data } = await SBService.myProfile()

                setLoader(false)
                setUser(data)
            } catch (e: any) {
                console.log(e)
                navigate('/auth/login')
            }
        }
        setLoader(true)
        loadUser()
    }, [])


    return <div className="py-6">
        <div className="flex items-center">
            <img className="w-[40px] rounded-full" src={getAssetFullUrl(user.profilePicture)}/> <span className="ml-3 text-sm font-exo text-gray-11">{user.name}</span>
        </div>
        <div className="mt-6 league-content-tab">
            <h2 className="uppercase font-medium">LỊCH SỬ GIẢI ĐẤU</h2>
            <div className="w-full bg-[#1E1E32] p-3 rounded mt-4">
                <TETabs className="w-full">
                    <TETabsItem
                        wrapperClass="w-1/2 bg-[#1E1E32]"
                        className="w-full py-3 customTab"
                        onClick={() => handleBasicClick("tab1")}
                        active={basicActive === "tab1"}
                        tag="button"
                        color="primary"
                    >
                        Chờ xác nhận
                    </TETabsItem>
                    <TETabsItem
                        wrapperClass="w-1/2 bg-[#1E1E32]"
                        className="w-full py-3 customTab"
                        onClick={() => handleBasicClick("tab2")}
                        active={basicActive === "tab2"}
                        tag="button"
                        color="primary"
                    >
                        Đã xác nhận
                    </TETabsItem>
                </TETabs>

                <TETabsContent className="text-[#B0B4BA]">
                    <TETabsPane className='' show={basicActive === "tab1"}>
                        {(!_.isEmpty(waitingConfirmMatches) ? waitingConfirmMatches.map( (match:any, key:any) => {
                                return !_.isEmpty(match) ? <HistoryItem key={`history-me-${key}`} match={match}/> : <></>
                            } ) : <></>)
                          }
                    </TETabsPane>
                    <TETabsPane className="font-exo text-sm" show={basicActive === "tab2"}>
                    {(!_.isEmpty(confirmedMatches) ? confirmedMatches.map( (match:any, key:any) => {
                                return !_.isEmpty(match) ? <HistoryItem key={`history-me-${key}`} match={match}/> : <></>
                            } ) : <></>)
                          }
                    </TETabsPane>
                </TETabsContent>
            </div>
        </div>
    </div>;
};

export default ProfilePage;