import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Loader from "../components/loader";


import { useRecoilState } from "recoil";
import {
    bottomBarState,
    loaderState,
    navbarTitleState,
    showBottomBarState,
    showNavbarState,
    userState
} from "../states/common";
import {useEffect} from "react";
import BottomBar from "../components/bottomBar";
import * as SBService from "../helpers/sportbookService";
import * as _ from "lodash";

const Layout = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useRecoilState(loaderState)
    const [navbarTitle , setNavbarTitle] = useRecoilState(navbarTitleState)
    const [showNavbar , setShowNavbar] = useRecoilState(showNavbarState)
    const [showBottomBar , setBottomBar] = useRecoilState(showBottomBarState)
    const [user , setUser] = useRecoilState(userState)


    useEffect(() => {
        setLoader(false)
        if (!localStorage.getItem('token')) {
            navigate('/auth/login')
        }

        const loadUser = async () => {
            try {
                const data = await SBService.myProfile()
                if (data && data.profilePicture) {
                    if (data.data.profilePicture.indexOf('https') === -1) {
                        data.data.profilePicture = 'https://admin.sportbook.vn/storage/admin/' + data.profilePicture
                    }
                } else {
                    data.data.profilePicture = 'https://placehold.co/96x96'
                }
                setLoader(false)
                setUser(data.data)
            } catch (e: any) {
                navigate('/auth/login')
            }
        }
        if (_.isEmpty(user.id)) {
            setLoader(true)
            loadUser()
        }
    }, [])


    return (
        <div className="main mx-auto px-4 pb-[124px] relative w-screen top-0">
            <Outlet />
            {showBottomBar ?  <BottomBar/> : <></>}
            {loader ? <Loader/> : <div/>}
        </div>
    )
};

export default Layout;