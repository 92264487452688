import {createBrowserRouter} from "react-router-dom";
import React from "react";
import Layout from "pages/layout";
import ProfilePage from "pages/user/profile";

import LoginPage from "pages/auth/login";
import LogoutPage from "pages/auth/logout";
import ForgotPasswordPage from "pages/auth/forgotPassword";
import AuthLayout from "pages/authLayout";
import WaitingConfirmMatchingPage from "pages/waitingConfirmMatching";
import LeaguePlayer from "./pages/league-player";


export const router = createBrowserRouter([
    {
        path: 'auth',
        element: <AuthLayout/>,
        children: [
            {
                path: "login",
                element: <LoginPage/>,
                index: true
            },
            {
                path: "logout",
                element: <LogoutPage/>,
                index: false
            },
            {
                path: "forgot-password",
                element: <ForgotPasswordPage/>
            }
        ],
    },
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                path: "/",
                element: <ProfilePage/>,
                index: true
            },
            {
                path: "profile",
                element: <ProfilePage/>,
                index: true
            },
            {
                path: "match/:id",
                element: <WaitingConfirmMatchingPage/>,
                index: true
            },
            {
                path: "league-players",
                element: <LeaguePlayer/>
            }
        ],

    },

]);

