import React from "react";
import Icon from "./icon";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {getAssetFullUrl} from "helpers/utils";


interface HistoryItemProp {
    match: any
}


const HistoryItem: React.FC<HistoryItemProp> = ({match}) => {
    const navigate = useNavigate()
    return <div className="w-full flex border-b border-gray-600 border-opacity-20 py-2 items-center"
                onClick={() => navigate(`/match/${match.id}`)}>
        <div className="w-[70%]">
            <div className="w-full flex flex-wrap">
                <div className="w-11/12 flex items-center">
                    <img className="w-[24px] h-[24px] rounded-full"
                         src={match.acceptor?.profilePicture ? getAssetFullUrl(match.acceptor?.profilePicture) : 'https://placehold.co/16x16'}/>
                    <span className="w-[90%] font-normal font-exo text-sm pl-2">
                        {match?.acceptor?.name}
                </span>
                </div>
                <div className="w-1/12 text-center">
                    <span>{match.isWaitingRivalConfirmed ? '-' : <span
                        className={(match.challengerPoint > match.acceptorPoint) ? 'text-[#EB9091]' : ((match.challengerPoint < match.acceptorPoint) ? 'text-[#0EB39E]' : '')}>{match.acceptorPoint}</span>}</span>
                </div>
            </div>

            <div className="w-full flex flex-wrap mt-2">
                <div className="w-11/12 flex items-center">
                    <img className="w-[24px] h-[24px] object-cover rounded-full"
                         src={match.challenger?.profilePicture ? getAssetFullUrl(match.challenger?.profilePicture) : 'https://placehold.co/16x16'}/>
                    <span className="w-[90%] font-normal font-exo text-sm pl-2">{match.challenger?.name} </span>
                </div>
                <div className="w-1/12 text-center">{match.isWaitingRivalConfirmed ? '-' : <span
                    className={(match.challengerPoint > match.acceptorPoint) ? 'text-[#0EB39E]' : ((match.challengerPoint < match.acceptorPoint) ? 'text-[#EB9091]' : '')}>{match.challengerPoint}</span>}</div>
            </div>

        </div>
        <div className="w-[30%] text-center font-exo pr-4 relative">
            {match.playedAt ? <> <p className="text-sm text-gray-500">{moment(match.playedAt).format('DD/MM/YY')}</p>
                <p className="text-sm text-gray-500">{moment(match.playedAt).format('HH:mm')}</p></> : <></>}

            <div className="absolute right-0 top-1/2 -translate-y-1/2">
                <Icon icon={'chevron-right'}/>
            </div>
        </div>
    </div>
}
export default HistoryItem