import React, { useEffect, useState, useReducer } from "react";
import { getAssetFullUrl } from "../../helpers/utils";
import * as SBService from "../../helpers/sportbookService"
import toast from "react-hot-toast";
import { useRecoilState, useRecoilValue } from "recoil";
import { loaderState, userState } from "../../states/common";
import Fancybox from "components/fancybox";
import { useNavigate } from "react-router-dom";
import { TECollapse, TERipple } from "tw-elements-react";
import Icon from "components/icon";
import RankIconItem from "./RankItem";
import Switch from "@mui/material/Switch";

interface PlayerCheckinCardProps {
    player: any,
    leagueId: any,
    index: number,
    onUpdateCheckin: (playerId:string, isCheckin: boolean) => any
}

const PlayerCheckinCard: React.FC<PlayerCheckinCardProps> = ({ player, leagueId, index, onUpdateCheckin }) => {
    const user = useRecoilValue(userState)
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);
    const [loader, setLoader] = useRecoilState(loaderState)
    const navigate = useNavigate();
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const callPlayer = async () => {
        setLoader(true)
        try {
            let playerInfor = await SBService.getUserById(player.player.id)
            if (playerInfor && playerInfor.phone) {
                window.open(`tel:${playerInfor.phone}`)
            } else {
                toast('Có lỗi xảy ra: Không thể lấy thông tin người chơi')
            }
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.error) {
                toast('Có lỗi xảy ra: Không thể lấy thông tin người chơi')
            } else {
                toast('Có lỗi xảy ra: ' + e.message)
            }
        }
        setLoader(false)
    }

    const matchingWithAnotherPlayer = () => {
        if (player.player.id != user.id && user.ranking?.class) {
            navigate(`/league/${leagueId}/competitor/${player.player.id}`)
        } else {
            if (!user.ranking?.class) {
                toast.error('Bạn chưa được khảo hạch!')
            }
        }
    }


    const checkIn = async () => {
        setLoader(true)
        try {
            await SBService.checkinLeagueByLeaguePlayerId(player.id);
            toast.success('Check-In thành công');
            // await loadCheckedInPlayer(1);
            return true
        } catch (e: any) {
            toast.error('Check-In thất bại');
            return false
        }
        setLoader(false)
    }

    const checkOut = async () => {
        setLoader(true)
        try {
            await SBService.checkoutLeagueByLeaguePlayerId(player.id);
            toast.success('Check-out thành công');
            return false
        } catch (e: any) {
            toast.error('Check-out thất bại');
            return true
        }
        setLoader(false)
    }

    const handleChange = async () => {
        if (player.isCheckin) {
            player.isCheckin = await checkOut()
        } else {
            player.isCheckin = await checkIn()
        }
        onUpdateCheckin(player.id, player.isCheckin)
    }


    return <div className="w-full w-fit">
        <div className="flex flex-wrap min-h-[50px] items-center w-full">
                <div className="w-[20%] flex items-center text-center">
                    <div className="w-1/2 text-left">
                    <RankIconItem rank={player.ranking ? (player.leaguePosition) : 0} isLeft={false}/>
                </div>
                <div className="w-1/2 text-left pl-3">
                    <span>{player.ranking}</span>
                </div>
            </div>
            <div className={'w-[40%] flex items-center'}>
                <Fancybox>
                    <a data-fancybox="gallery" href={getAssetFullUrl(player?.player?.profilePicture)}>
                        <img className="w-[24px] h-[24px] object-cover object-center rounded-full mr-2" src={getAssetFullUrl(player?.player?.profilePicture)} />
                    </a>
                </Fancybox>
                <a className="w-full" onClick={() => matchingWithAnotherPlayer()}><span className="font-normal font-exo text-sm pl-2">{player?.player?.name}</span></a>
            </div>

            <span className="w-[20%] font-normal font-exo text-sm pl-2">{ player.player?.ranking?.class ?? '--'}</span>
            <span>
                <Switch
                    checked={player.isCheckin}
                    onChange={() => handleChange()}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </span>

        </div>

    </div>
}

export default PlayerCheckinCard