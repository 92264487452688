import axios from 'axios';
import {CONSTANT} from '../shared/constant'
import * as _ from 'lodash'


const callApi = async function (endpoint: string, method = 'GET', body:any = null, version = '', token?: any, noCheckToken?:boolean) {

    let API_URL = CONSTANT.baseUrl + (version ? version : CONSTANT.apiVersion)
    if (endpoint.charAt(0) == '/') {
        endpoint = endpoint.substring(1);
    }

    if (!token && !noCheckToken) {
        token = await getTokenFromStorageData()
    }
    let headers =  {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "*",
        "Authorization": token ? 'Bearer ' + token : ''
    }


    const callApiData = async () => {
        switch (method) {
            case 'POST':
                const postPromise =  await axios.post(`${API_URL}/${endpoint}`, body, {
                    headers:  headers
                })
                // using .then, create a new promise which extracts the data
                // return it
                return postPromise
                break;
            case 'PATCH':
                const patchPromise =  await axios.patch(`${API_URL}/${endpoint}`, body, {
                    headers:  headers
                })
                // using .then, create a new promise which extracts the data
                // return it
                return patchPromise
                break;
            default:
                const promise = await axios.get(`${API_URL}/${endpoint}`, {
                    headers:  headers
                })
                // using .then, create a new promise which extracts the data

                // return it
                return promise
        }
    }


    let rsp = callApiData().then((response) => response.data)
    return rsp
}


const callExternalApi = async function (endpoint: string, method = 'GET', body = null, headers: any) {
    const callApiData = async () => {
        switch (method) {
            case 'POST':
                const postPromise =  await axios.post(`${endpoint}`, body, {
                    headers:  headers
                })
                // using .then, create a new promise which extracts the data
                // return it
                return postPromise
                break;
            default:
                if (!_.isEmpty(body)) {
                    let parameters = ''
                    _.forOwn(body, function (val:any, key: string) {
                        parameters += `&${key}=${val}`
                    })
                    if (endpoint.indexOf('?') > -1) {
                        endpoint += parameters
                    } else {
                        endpoint += ('?' + parameters.substring(1))
                    }
                }
              
                const promise = await axios.get(`${endpoint}`, {
                    headers:  headers
                })
                // using .then, create a new promise which extracts the data

                // return it
                return promise
        }
    }


    let rsp = callApiData().then((response) => response.data)
    return rsp
}

const getTokenFromStorageData = async function () {
    return localStorage.getItem('token');
}

const getRefreshTokenFromStorageData = function () {
    return localStorage.getItem('refreshToken');
}

const refreshToken = async (version: any = 'v1', token: string) => {
    let headers =  {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "*",
        "Authorization": "Bearer " + token
    }


    let API_URL = CONSTANT.baseUrl + (version ? version : CONSTANT.apiVersion)
    console.log(`${API_URL}/${version}/auth/refresh`)
    const promise = await axios.post(`${API_URL}/auth/refresh`, {
        headers:  headers
    })
    // using .then, create a new promise which extracts the data

    // return it
    return promise
}

export {
    callApi,
    callExternalApi
}
