import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as SBService from "../helpers/sportbookService";
import {useRecoilState} from "recoil";
import {loaderState} from "../states/common";
import toast from "react-hot-toast";
import moment, {ISO_8601} from "moment";
import 'moment/locale/vi'
import Navbar from "../components/navbar";
import Icon from "../components/icon";

import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
    TEModalFooter,
} from "tw-elements-react";
import {currencyToNumber, file2Base64, getAssetFullUrl, toCurrency} from "helpers/utils";
import {getMatchResultById} from "../helpers/sportbookService";

const WaitingConfirmMatchingPage = () => {
    const [, setLoader] = useRecoilState(loaderState)
    const [league, setLeague] = useState({
        id: null,
        name: null
    })
    const [currentMatch, setCurrentMatch] = useState({
        id: '',
        status: null,
        createdAt: '',
        venueId: '',
        tableName: '',
        acceptorId: '',
        acceptorPoint: 0,
        challengerId: '',
        challengerPoint: 0,
        playedAt: '',
        endedAt: '',
        race: 0,
        billAmount: 0,
        billPhoto: '',
        challengerLeaguePlayer: {
            leaguePosition: 0
        },
        acceptorLeaguePlayer: {
            leaguePosition: 0
        }
    })

    const [venue, setVenue] = useState({
        name: ''
    })

    const [showModal, setShowModal] = useState(false);
    const [player, setPlayer] = useState({
        id: null,
        name: null,
        profilePicture: '',
        playerRankInLeague: 10,
        leagueScore: 40,
        ranking: {code: '', class: ''},
        score: 2910
    })
    const [challenger, setChallenger] = useState({
        id: null,
        name: null,
        profilePicture: '',
        playerRankInLeague: 11,
        leagueScore: 38,
        ranking: {code: '', class: ''},
        score: 2800
    })
    const navigate = useNavigate();

    const {id} = useParams()

    const [dataEdit, setDataEdit] = useState({
        "tableName": 1,
        "billPhoto": "",
        "note": "",
        "playedAt": moment().format('YYYY-MM-DD[T]HH:mm'),
        "endedAt": moment().format('YYYY-MM-DD[T]HH:mm'),
        "billAmount": 0
    })

    const [challengerScore, setChallengerScore] = useState<any>('')
    const [playerScore, setPlayerScore] = useState<any>('')

    const [challengerPoint, setChallengerPoint] = useState<any>('')
    const [acceptorPoint, setAcceptorPoint] = useState<any>('')

    const [challengerOneShotPoint, setChallengerOneShotPoint] = useState<any>(null);
    const [acceptorOneShotPoint, setAcceptorOneShotPoint] = useState<any>(null);

    const loadCompetitionData = async () => {
        try {
            if (id) {
                const matchData = await SBService.getGetMatchDetailById(id)
                const matchResultRsp = await SBService.getMatchResultById(id)
                const finalResult = matchResultRsp.data[0]
                setChallengerPoint(finalResult.challengerPoint)
                setAcceptorPoint(finalResult.acceptorPoint)
                if (matchData.data) {
                    setCurrentMatch(matchData.data)
                }
                if (matchData.data.league) {
                    if (matchData.data.league.startDate) {
                        matchData.data.league.startDateObj = moment(matchData.data.league.startDate, ISO_8601)
                        matchData.data.league.endDateObj = moment(matchData.data.league.endDate, ISO_8601)
                    }
                    setLeague(matchData.data.league)
                }
                if (matchData.data.venue) {
                    setVenue(matchData.data.venue)
                }
                if (matchData.data.acceptor) {
                    setPlayer(matchData.data.acceptor)
                }
                if (matchData.data.challenger) {
                    setChallenger(matchData.data.challenger)
                }
                if (matchData.data.acceptorLeaguePlayer) {
                    setPlayerScore(matchData.data.acceptorLeaguePlayer)
                }
                if (matchData.data.challengerLeaguePlayer) {
                    setChallengerScore(matchData.data.challengerLeaguePlayer)
                }
                setChallengerOneShotPoint(matchData.data.challengerOneShotPoint)
                setAcceptorOneShotPoint(matchData.data.acceptorOneShotPoint)
                if (matchData.data.billAmount) {
                    handleEditData('billAmount', matchData.data.billAmount)
                }
            }

        } catch (e: any) {
            if (e.response && e.response.data && e.response?.data.error) {
                toast('Có lỗi xảy ra: ' + e.response.data.error)
            } else {
                toast('Có lỗi xảy ra: ' + e.message)
            }
            navigate(-1)
        }

        setLoader(false)
    }


    useEffect(() => {
        document.title = "Chi tiết trận đấu"
        setLoader(true)
        const loadData = async () => {
            await loadCompetitionData()
        }
        loadData();
    }, [])


    const updateTableMatch = async (tableName: string) => {
        if (currentMatch.tableName !== tableName) {
            try {
                let body = {
                    "tableName": tableName + '',
                    "venueId": currentMatch.venueId
                }
                await SBService.updateMatchTable(currentMatch.id, body)
            } catch (error) {
                toast.error('Lỗi cập nhật trận đấu!')
            }
        }
    }

    const handleEditData = async (field: string, value: string) => {
        if (field === 'billAmount') {
            value = currencyToNumber(value) + ''
        }

        if (value && field in dataEdit) {
            setDataEdit({...dataEdit, [field]: value})
        }
        if (field === 'tableName') {
            await updateTableMatch(value)
        }
    }

    const handleUploadIMG = async (field: string, e: any) => {
        const file = e.target.files[0]
        if (file) {
            let img64
            img64 = await file2Base64(file) + ''
            if (img64) {
                await handleEditData(field, img64)
            }
        }
    }

    const handleConfirmMatch = async () => {
        if (challengerPoint === acceptorPoint && challengerPoint === currentMatch.race) {
            toast.error('Điểm số không hợp lệ')
        }
        setLoader(true)
        let body = {
            "challengerOneShotPoint": challengerOneShotPoint,
            "acceptorOneShotPoint": acceptorOneShotPoint,
            "challengerPoint": challengerPoint,
            "acceptorPoint": acceptorPoint,
            "challengerId": currentMatch.challengerId,
            "acceptorId": currentMatch.acceptorId,
            "billPhoto": dataEdit.billPhoto.split(',')[1],
            "playedAt": dataEdit.playedAt ? dataEdit.playedAt : currentMatch.playedAt,
            "endedAt": dataEdit.endedAt ? dataEdit.endedAt : currentMatch.endedAt,
            "billAmount": Number(dataEdit.billAmount)
        }
        try {
            if ((dataEdit.billPhoto || currentMatch.billPhoto) && Number(dataEdit.billAmount) > 0) {
                setLoader(true)
                const rsp = await SBService.confirmMatch(currentMatch.id, body)
                if (rsp) {
                    setLoader(false)
                    setShowModal(true)
                }
            } else {
                if (!dataEdit.billPhoto && !currentMatch.billPhoto) toast.error('Chưa có hình hoá đơn')
                if (Number(dataEdit.billAmount) <= 0) toast.error('Chưa điền Tổng Bill!')
            }
        } catch (error) {
            setLoader(false)
            toast.error('Lỗi server cập nhật trận đáu!')
        }
        setLoader(false)
    }


    return <div className="py-6">
        <Navbar title={"Chi tiết trận đấu"}/>
        {currentMatch.id ? <>
            <h1 className="heading-8 text-2xl font-medium uppercase text-gray-11 mb-4 text-center">{league.name}</h1>
            <div className="w-full flex font-exo text-gray-11 text-sm mt-2">
                <div className="w-1/2 flex justify-end  items-center px-2"><span
                    className="px-2">{challenger.name}</span> <img className="w-6 h-6 rounded-full text-center"
                                                                   src={getAssetFullUrl(challenger.profilePicture)}/>
                    <span className="px-2 font-bold">{challengerPoint}</span></div>
                <div className="w-1/2 flex justify-start items-center"><span
                    className="px-2 font-bold">{acceptorPoint}</span> <img
                    className="w-6 h-6 rounded-full  text-center" src={getAssetFullUrl(player.profilePicture)}/> <span
                    className="px-2">{player.name}</span></div>
            </div>
            <div className="mt-2 items-center justify-center flex w-full">
                {currentMatch.status === 'SUBMITTED' ? <button
                    className="block w-1/2 h-12 bg-[#16120C] text-center uppercase text-sm rounded-[0.5rem] border border-[#4D3000] mt-6 text-[#FFCA16] ml-2"
                >Đang chờ xác nhận
                </button> : ''}
                {currentMatch.status === 'ACCEPTED' ? <button
                    className="block w-1/2 h-12 bg-[#777B84] text-center uppercase text-sm rounded-[0.5rem] border border-[#0EB39E] mt-6 text-black ml-2"
                >Đang thi đấu
                </button> : ''}
            </div>
            <div className="mt-2 items-center justify-center flex w-full">

            </div>
            <div className="w-full px-2 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4 font-exo text-xs text-gray-11">
                <div className="w-full">
                    <div className="flex">
                        <div className="w-1/2 p-2 relative">
                            <p className="uppercase">Địa điểm</p>
                            <p>{venue.name}</p>
                        </div>
                        <div className="w-1/2 p-2 relative flex items-end text-sm">
                            <div className="uppercase">Bàn:</div>
                            <input type="text" className="border-0 bg-inherit pl-1 table-input w-full"
                                   defaultValue={currentMatch.tableName} maxLength={2}
                                   onBlur={(e) => handleEditData('tableName', e.target.value)}/>
                            <div className="mb-[2px]">
                                <Icon icon={'pencil'}/>
                            </div>
                        </div>
                    </div>
                    <div className="divider w-full mb-0"></div>
                    <div className="flex">
                        <div className="w-1/2 border-r border-[--gray-border-17] p-2 relative">
                            <p>Bắt đầu</p>
                            <input className="border-0 bg-inherit text-sm w-full" type="datetime-local"
                                   defaultValue={moment(currentMatch.playedAt).format('YYYY-MM-DD[T]HH:mm')}
                                   onChange={(e) => handleEditData('playedAt', moment(e.target.value).toISOString())}/>
                        </div>
                        <div className="w-1/2 p-2 relative">
                            <p>Kết thúc</p>
                            <input className="border-0 bg-inherit text-sm w-full" type="datetime-local"
                                   defaultValue={moment(currentMatch.endedAt).format('YYYY-MM-DD[T]HH:mm')}
                                   onChange={(e) => handleEditData('endedAt', moment(e.target.value).toISOString())}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full px-4 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4 font-exo text-xs text-gray-11">
                <div className="flex text-sm">
                    <div className="w-1/2 border-r border-[--gray-border-17] p-2 relative">
                        <div className="flex items-center mb-2"><img className="w-6 h-6 rounded-full text-center"
                                                                     src={getAssetFullUrl(challenger.profilePicture)}/><span
                            className="text-lg font-bold text-white px-2">{challenger.name}</span></div>
                        <p className="mb-1">XH mùa giải: {currentMatch.challengerLeaguePlayer.leaguePosition || '--'}</p>
                        <p className="mb-3">Điểm: {challengerScore.won * 3 + challengerScore.drew}</p>
                        <p className="mb-1">Trình độ: {challenger.ranking.class}</p>
                        <p className="">Điểm: {challenger.score}</p>
                    </div>
                    <div className="w-1/2 p-2 pl-4 relative">
                        <div className="flex items-center mb-2"><img className="w-6 h-6 rounded-full text-center"
                                                                     src={getAssetFullUrl(player.profilePicture)}/><span
                            className="text-lg font-bold text-white px-2">{player.name}</span></div>
                        <p className="mb-1">XH mùa giải: {currentMatch.acceptorLeaguePlayer.leaguePosition || '--'}</p>
                        <p className="mb-3">Điểm: {playerScore.won * 3 + playerScore.drew}</p>
                        <p className="mb-1">Trình độ: {player.ranking.class}</p>
                        <p className="">Điểm: {player.score}</p>
                    </div>
                </div>
            </div>
            <div className="w-full px-4 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4 font-exo text-xs text-gray-11 relative">
                <div className="w-full text-lg">
                    Số chấm của trận đấu
                </div>
                <div className="flex">
                    <div className="w-1/2 p-2 relative text-center">
                        <input className="border-0 bg-white text-3xl w-20 text-[#43484E] rounded-[0.4rem] text-center h-[45px] font-semibold"
                               value={challengerOneShotPoint}
                               onChange={(e) => setChallengerOneShotPoint(e.target.value)}/>
                    </div>
                    <div className="w-1/2 p-2 relative text-center">
                        <input className="border-0 bg-white text-3xl w-20 text-[#43484E] rounded-[0.4rem] text-center h-[45px] font-semibold"
                               value={acceptorOneShotPoint}
                               onChange={(e) => setAcceptorOneShotPoint(e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="w-full px-4 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4 font-exo text-xs text-gray-11 relative">
                <div className="flex h-[50px] justify-between">
                    <div>
                        <p>Tổng Bill</p>
                        <input type="text"
                               className="border-0 bg-inherit pl-1 table-input w-[90%] text-lg text-white font-[600]"
                               value={toCurrency(dataEdit.billAmount.toString())}
                               onChange={(e) => handleEditData('billAmount', e.target.value)}/>
                    </div>
                    <label
                        className="mt-1 font-exo bg-[#0EB39E] text-white border-none rounded text-sm w-[50%] h-[36px] flex justify-center items-center"
                    ><Icon icon="camera"/>
                        <span className="text-sm ml-3">Chụp lại bill</span>
                        <input className="hidden" type="file"
                               onChange={(e) => handleUploadIMG('billPhoto', e)}/>
                    </label>
                </div>
                <div>
                    {dataEdit.billPhoto ? <img className="block" src={dataEdit.billPhoto}/> : (currentMatch.billPhoto ?
                        <img className="block" src={currentMatch.billPhoto}/> : '')}
                </div>
            </div>

            <div
                className="w-full px-4 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4 font-exo text-xs text-gray-11 relative">
                <p className="text-md mb-2">Ghi chú</p><textarea
                className="border-none rounded bg-inherit pl-1 table-input w-full font-medium bg-white text-sm h-24 p-3 text-gray-700"
                onChange={(e) => handleEditData('note', e.target.value)}
                spellCheck="false"></textarea></div>
            {currentMatch.status !== 'ENDED' ? <button
                className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
                onClick={() => handleConfirmMatch()}
            >Xác nhận
            </button> : <></>}

        </> : <></>}


        <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
            <TEModalDialog className="h-full" centered={true}>
                <TEModalContent>
                    <TEModalBody className="bg-[#1E1E32] text-center">
                        <h4 className="font-bold text-xl">
                            XÁC NHẬN THÀNH CÔNG
                        </h4>
                        <p className="text-left mt-4 font-exo">
                            Thông tin trận đấu đã được xác nhận thành công
                        </p>
                    </TEModalBody>
                    <TEModalFooter className="bg-[#1E1E32] flex justify-center border-none w-full">
                        <TERipple rippleColor="light">
                            <button
                                type="button" onClick={() => {
                                navigate('/')
                            }}
                                className="w-full px-6 py-4 inline-block rounded  bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow"
                            >
                                Quay về trang chủ
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

    </div>;
};

export default WaitingConfirmMatchingPage;