import { atom } from "recoil";

export const loaderState = atom({
    key: "loader",
    default: false,
});

export const showNavbarState = atom({
    key: "showNavbar",
    default: true,
});

export const navbarTitleState = atom({
    key: "navbarTitle",
    default: "",
});

export const tokenState = atom( {
    key: "token",
    default: null,
})

export const bottomBarState = atom( {
    key: "bottomBar",
    default: 'profile',
})


export const showBottomBarState = atom( {
    key: "showBottomBar",
    default: true,
})

export  const userState = atom({
    key: "user",
    default: {
        id: '',
        name: '',
        phone: '',
        email: '',
        ranking: {code: "--", class: "--"},
        isCalibrated: false,
        isExpert: false,
        score: 0,
        profilePicture: ''
    }
})